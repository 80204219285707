import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { ProductModel, ResponseProductsList } from './products.response';

export class ProductsRepository extends AxiosFactory {
  constructor() {
    super('/products');
  }

  async getProducts(): Promise<CustomAxiosResponse<ResponseProductsList>> {
    return await this.axiosApi.get('');
  }

  async createProduct(productForm: Omit<ProductModel, '_id'>): Promise<CustomAxiosResponse<ProductModel>> {
    return await this.axiosApi.post('', { ...productForm });
  }
  async updateProduct(productForm: ProductModel): Promise<CustomAxiosResponse<ProductModel>> {
    return await this.axiosApi.put('/' + productForm._id, { ...productForm });
  }
  async deleteProduct(product_id: string): Promise<CustomAxiosResponse<boolean>> {
    return await this.axiosApi.delete('/' + product_id);
  }
}
