import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';

export class MiscRepository extends AxiosFactory {
  constructor() {
    super('/misc');
  }

  async setFast(): Promise<CustomAxiosResponse<void>> {
    return await this.axiosApi.put('/toggle-fast');
  }

  async getFast(): Promise<CustomAxiosResponse<boolean>> {
    return await this.axiosApi.get('/is-enable-fast');
  }
}
