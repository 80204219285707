import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { SystemModel } from './system.response';

export class SystemRepository extends AxiosFactory {
  constructor() {
    super('/system');
  }

  async getConfiguration(): Promise<CustomAxiosResponse<SystemModel>> {
    return await this.axiosApi.get('/configuration');
  }
  async updateConfiguration(dataForm: Partial<SystemModel>): Promise<CustomAxiosResponse<SystemModel>> {
    return await this.axiosApi.put('/configuration', { ...dataForm });
  }
}
