import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    style: _normalizeStyle(`width:${$setup.size};height:${$setup.size}`),
    viewBox: `0 0 24 24`
  }, [
    _createElementVNode("path", {
      fill: "currentColor",
      d: $setup.pathIcon
    }, null, 8, _hoisted_1)
  ], 4))
}