import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './index.scss';
import { createPinia } from 'pinia';
import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';
import createContainerRepositories from './repositories/repository.container';

const pinia = createPinia();
const app = createApp(App);

app.use(createContainerRepositories).use(VueAnimXyz).use(pinia).use(router).mount('#app');
