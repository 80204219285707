import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { GetPaymentsDto } from './payments.inputs';
import { PaymentModel, ResponsePayments } from './payments.response';

export class PaymentsRepository extends AxiosFactory {
  constructor() {
    super('/payments');
  }

  async getPayments(page_options: GetPaymentsDto, user_id?: string, payment_provider_id?: string): Promise<CustomAxiosResponse<ResponsePayments>> {
    return await this.axiosApi.get('', {
      params: { ...page_options, user_id, payment_provider_id }
    });
  }

  async chargebackPayments(payment_id: string): Promise<CustomAxiosResponse<PaymentModel>> {
    return await this.axiosApi.put('/' + payment_id + '/chargeback');
  }
  async refundPayments(payment_id: string): Promise<CustomAxiosResponse<PaymentModel>> {
    return await this.axiosApi.put('/' + payment_id + '/refund');
  }
}
