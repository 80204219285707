import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { AdminToken } from './auth.responses';

export class AuthRepository extends AxiosFactory {
  constructor() {
    super('/admin');
  }

  async loginDefault(admin_email: string, admin_password: string): Promise<CustomAxiosResponse<AdminToken>> {
    return await this.axiosApi.post('/login', { admin_email, admin_password });
  }
}
