import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { GetUsersDto } from './users.inputs';
import { ResponseUsers, UserModel } from './users.response';

export class UsersRepository extends AxiosFactory {
  constructor() {
    super('/users');
  }

  async getUsers(page_options: GetUsersDto): Promise<CustomAxiosResponse<ResponseUsers>> {
    return await this.axiosApi.get('', {
      params: page_options
    });
  }
  async getUser(user_id: string): Promise<CustomAxiosResponse<UserModel>> {
    return await this.axiosApi.get(`/${user_id}`);
  }
  async updateUser(user_id: string, user_data: Partial<UserModel>): Promise<CustomAxiosResponse<UserModel>> {
    return await this.axiosApi.put(`/${user_id}`, {
      ...user_data
    });
  }
}
