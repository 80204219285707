import dashboardLayoutVue from '@/layouts/dashboard-layout.vue';
import { useAuthStore } from '@/stores/auth.store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: dashboardLayoutVue,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/DashboardView.vue')
      },
      {
        path: 'requests',
        name: 'RequestList',
        props: route => ({ user_email: route.query.user_email }),
        component: () => import(/* webpackChunkName: "Requests" */ '../views/RequestsView.vue')
      },
      {
        path: 'users',
        name: 'UsersList',
        component: () => import(/* webpackChunkName: "UsersList" */ '../views/UsersView.vue')
      },
      {
        path: 'users/:user_id',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "UserProfile" */ '../views/UserProfileView.vue'),
        props: true
      },
      {
        path: 'payments',
        name: 'PaymentsList',
        component: () => import(/* webpackChunkName: "PaymentsList" */ '../views/PaymentsView.vue')
      },
      {
        path: 'products',
        name: 'ProductsList',
        component: () => import(/* webpackChunkName: "ProductsList" */ '../views/ProductsView.vue')
      },
      {
        path: 'system',
        name: 'System',
        component: () => import(/* webpackChunkName: "SystemList" */ '../views/SystemView.vue')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "Auth" */ '../views/AuthView.vue'),
    meta: {
      redirectIsAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (to.hash) {
          resolve({
            el: to.hash,
            behavior: 'smooth',
            top: 20
          });
        } else {
          resolve({
            top: 0,
            behavior: 'smooth'
          });
        }
      }, 1);
    });
  }
});

router.beforeEach((to, from, next) => {
  const AuthStore = useAuthStore();
  if (to.meta.requiresAuth && !AuthStore.userIsLogged) return next({ name: 'Auth' });
  else if (to.meta.redirectIsAuth && AuthStore.userIsLogged) return next({ name: 'Dashboard' });

  return next();
});

export default router;
