import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { GetRequestsDto } from './requests.inputs';
import { RequestModel, responseRequests } from './requests.response';

export class RequestsRepository extends AxiosFactory {
  constructor() {
    super('/requests');
  }

  async getRequests(page_options: GetRequestsDto): Promise<CustomAxiosResponse<responseRequests>> {
    return await this.axiosApi.get('', {
      params: page_options
    });
  }
  async setRequestStatus(request_id: string, request_status: RequestModel['request_status']): Promise<CustomAxiosResponse<RequestModel>> {
    return await this.axiosApi.put(`/${request_id}`, {
      request_status
    });
  }
}
