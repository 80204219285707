import { App } from 'vue';
import { AuthRepository } from './auth/auth.repository';
import { AnalyticsRepository } from './analytics/analytics.repository';
import { RequestsRepository } from './requests/requests.repository';
import { UsersRepository } from './users/users.repository';
import { PaymentsRepository } from './payments/payments.repository';
import { MiscRepository } from './misc/misc.repository';
import { ProductsRepository } from './products/products.repository';
import { SystemRepository } from './system/system.repository';

export default {
  install: (app: App) => {
    app.provide('AuthRepository', new AuthRepository());
    app.provide('AnalyticsRepository', new AnalyticsRepository());
    app.provide('RequestsRepository', new RequestsRepository());
    app.provide('UsersRepository', new UsersRepository());
    app.provide('PaymentsRepository', new PaymentsRepository());
    app.provide('MiscRepository', new MiscRepository());
    app.provide('ProductsRepository', new ProductsRepository());
    app.provide('SystemRepository', new SystemRepository());
  }
};
