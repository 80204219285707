import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { AnalyticsResponse } from './analytics.response';

export class AnalyticsRepository extends AxiosFactory {
  constructor() {
    super('/analytics');
  }

  async getanalyticsBasic(): Promise<CustomAxiosResponse<AnalyticsResponse>> {
    return await this.axiosApi.get('');
  }
}
